import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { SideBar } from '../SideBar';
import AlertSuccess from "../../images/alert-success.svg";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import RedCross from "../../images/red-cross.svg";
import GreenTick from "../../images/green-tick.svg";
import ListGroup from 'react-bootstrap/ListGroup';
import SubmitIcon from "../../images/send-white.svg";
import DownArrow from "../../images/down-white.svg";
import Upload from "../../images/upload-file.svg";
import BackArrow from "../../images/back-arrow.svg";
import Time from "../../images/time.svg";
import '../../scss/table.scss';
import '../../scss/tabs.scss';
import '../../scss/status.scss';
import './Carriers.scss';
import './BenefitQuestions.scss';
import './StickyNav.scss';
import './BenefitConfig.scss';
import client from "../../utils/ApiClient";
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Modal } from 'react-bootstrap';
import { Admin, Approve, ApprovedStatus, CarrierDetailsActiveTab, CheckPlanOverrideExists, DraftStatus, getSelectedRole, InsertIsPlanOverrides, Manager, OverrideButton, OverrideCatchallDataPlanAnswers, PlanDetailsActiveTab, ReviewedStatus, Submit, SubmitOrApproveSiteConfigurationsURL, SubmittedStatus, SystemAdmin, WithOutOverrideButton, GetOverRidePreference, SelectedYear, BenefitYears, year, GetBenefitYearsForACarrier, BenefitYear, prod, prodcentus, prodceus2 } from '../../Constants/AppConstants';
import { SiteConfig } from './SiteConfig';
import { Plans } from './Plans';
import { CarrierSettings } from './CarrierSettings';
import { ReviewStatus, StatusModel } from '../../Interfaces/ReviewStatus';
import { GetKeyValueFromLocalStorage, RemoveKeyFromLocalStorage, SetKeyToLocalStorage } from '../../Constants/LocalStorage';
import { ApprovedIcon, FutureConfigIcon, InProgressIcon } from '../../components/Common/CommonComponents';

// Carrier details component.
export const CarrierDetails = () => {

    // Uselocation from react router dom.
    const location = useLocation();

    // Use navigation.
    const navigate = useNavigate();

    // State variable for is  approved or published.
    const [isApprovedOrPublished, setIsApprovedOrPublished] = useState<boolean>(false);

    // State variable for button title.
    const btnTitle: string =
        getSelectedRole()?.toString()?.toLowerCase() === Admin?.toString()?.toLowerCase()
            || getSelectedRole()?.toString()?.toLowerCase() === SystemAdmin?.toString()?.toLowerCase() ?
            Approve :
            getSelectedRole()?.toString()?.toLowerCase() === Manager?.toString()?.toLowerCase() ?
                Submit : Submit;

    // Gets the button title.
    const getBtnTitle = () => {
        return btnTitle === Approve ? ApprovedStatus : btnTitle === Submit ? SubmittedStatus : DraftStatus
    }

    // State varible for loader.
    const [loading, setLoading] = useState(false);

    // State variable for active tab.
    const [activeTab, setActiveTab] = useState('Plans');

    // State varible for successful configuration alert.
    const [saveSiteConfigurationAlert, setSaveSiteConfigurationAlert] = useState<boolean>(false);

    // State variable for is acknowledged.
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);

    // State variable for review statuses.
    const [reviewStatuses, setReviewStatuses] = useState<ReviewStatus>({});

    // State variable for submitted status.
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    // State variable for draft status.
    const [isDraft, setIsDraft] = useState<boolean>(false);

    // State variable for draft status.
    const [isAtleastOneSubmitted, setAtleastOneSubmitted] = useState<boolean>(false);

    // State variable for refreshing site configurations component.
    const [refreshSiteConfigurations, setRefreshSiteConfigurations] = useState<boolean>(false);

    // State variable for site configuration alert message.
    const [saveSiteConfigurationAlertMessage, setSaveSiteConfigurationAlertMessage] = useState<string>('Saved successfully');

    // State variable for is save site submitted.
    const [isSaveSiteSubmitted, setIsSaveSiteSubmitted] = useState<boolean>(false);

    // Active insurance carrier.
    const { activeInsuranceCarrier, planDetails } = location.state ?? 0;

    const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);

    // To get the User OverRidePreference 
    const [isOverRidePrefered, setIsOverRidePrefered] = useState<boolean | null>(null);

    // State variable to enable or disable submit or approve button.
    const [isbuttonEnabled, setIsbuttonEnabled] = useState<boolean>(false);

    // State variable to enable or disable submit or approve button.
    const [isFutureConfig, setIsFutureConfig] = useState<boolean>(false);

    // State variable to set the selected benefit year and status.
    const [selectedData, setSelectedData] = useState<{ benefitYear: number; status: string; }>({
        benefitYear: 0,
        status: ""
    });

    const status = selectedData?.status?.toLowerCase() === "in progress"
        ? "review"
        : selectedData?.status?.toLowerCase();

    //if activeInsuranceCarrier is undefined
    if (!activeInsuranceCarrier) {
        window.location.href = "/";
    }

    // To check if the carrier has plan overrides or not based on this we are showing plan override popup.
    const checkPlanOverrideExistsOrNot = async (year: any) => {
        try {
            const apiUrl = `${CheckPlanOverrideExists}/${activeInsuranceCarrier.insuranceCarrierId}/${year}`;
            const response = await client.get(apiUrl);
            if (response.data === true) {
                GetOverRidePreferencedetails(year);
                setIsSecondModalVisible(true);                
            } else {
                setIsAcknowledged(false);
                setIsSaveSiteSubmitted(!isSaveSiteSubmitted);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // To get the plan override preference
    const GetOverRidePreferencedetails = async (year: any) => {
        try {
            const apiUrl = `${GetOverRidePreference}/${activeInsuranceCarrier.insuranceCarrierId}/${year}`;
            const response = await client.get(apiUrl);
            if (response.data === 1) {
                setIsOverRidePrefered(true);
                setSelectedOption("allPlans");
            } else if (response.data === 0) {
                setIsOverRidePrefered(false);
                setSelectedOption("plansWithoutOverrides");
            }
            else {
                setIsOverRidePrefered(null);
                setSelectedOption(null);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // Gets the benefityears based on carrierid
    const getBenefitYearsForACarrier = () => {
        client.get(`${GetBenefitYearsForACarrier}/${activeInsuranceCarrier?.insuranceCarrierId}`)
            .then((response) => {
                SetKeyToLocalStorage(BenefitYears, JSON.stringify(response.data));
                let selectedBenefitYear = response?.data?.find((currentyear: any) => Number(currentyear.benefitYear) === year);
                setSelectedBenefitYearFromLocalStorage(response?.data, selectedBenefitYear);
            }).catch((error) => {
            }).finally(() => {
            })
    }
    
    const allItemsReviewed = () :boolean => {
     return Object.values(reviewStatuses).every(status => status.isReviewed);
    }

    const selectedYear = isbuttonEnabled ? year + 1 : year;

    // Handles the submit/approve site config dropdown.
    const handleNewPopUp = () => {
        checkPlanOverrideExistsOrNot(selectedYear);            
        setIsAcknowledged(false);
        setIsSaveSiteSubmitted(!isSaveSiteSubmitted);
    };

    // Handles the tab selection.
    const handleTabSelect = (selectedTab: any) => {
        setActiveTab(selectedTab);
        SetKeyToLocalStorage(CarrierDetailsActiveTab, selectedTab);
    };

    // Navigates to carrier page.
    const navigateToCarrierPage = () => {
        RemoveKeyFromLocalStorage(CarrierDetailsActiveTab);
        RemoveKeyFromLocalStorage(PlanDetailsActiveTab);
        RemoveKeyFromLocalStorage(SelectedYear);
        RemoveKeyFromLocalStorage(BenefitYears);
        RemoveKeyFromLocalStorage(BenefitYear);
        navigate("/Carriers");
    }

    // Submit or approve the site config data answers.
    const submitOrApproveSiteConfigurations = async (status: string, templateSheetBenefitIds: string, alertMessage: string, isClickedFromSbtBtnPopUp: boolean, year: any): Promise<boolean> => {
        let IsDraft: boolean = status === DraftStatus;
        const submitOrApproveSiteConfigModel = {
            insuranceCarrierId: activeInsuranceCarrier?.insuranceCarrierId,
            insuranceHealthPlanID: planDetails?.insuranceHealthPlanID?.toString() ?? '',
            templateSheetBenefitIDs: templateSheetBenefitIds,
            benefitYear: year,
            status: status,
            actionUser: GetKeyValueFromLocalStorage("username") ?? ''
        };
        try {
            let response = await client.post(SubmitOrApproveSiteConfigurationsURL, submitOrApproveSiteConfigModel);
            if (response.status === 200) {
                if (!IsDraft) {
                    setSaveSiteConfigurationAlert(true);
                    setSaveSiteConfigurationAlertMessage(alertMessage);
                    setTimeout(() => { setSaveSiteConfigurationAlert(false); }, 3000);
                }
                 setRefreshSiteConfigurations(!refreshSiteConfigurations);
            }
            return true;
        } catch (error) {
            setSaveSiteConfigurationAlert(false);
            return false;
        }
    };


    // Change status to reviewed or draft.
    const changeStatusToReviewedOrDraft = async (groupName: string, status: string, templateSheetBenefitIds: string, year: any): Promise<boolean> => {
        let alertMessage: string = status === ReviewedStatus ? `Reviewed ${year} ${groupName} successfully` : `${status} ${year} ${groupName}  created successfully`;
        let isSuccess: boolean = await submitOrApproveSiteConfigurations(status, templateSheetBenefitIds, alertMessage, false,year);
        return isSuccess;
    }

    // Submit or approves the site configuration group.
    const onSubmitOrApproveBtnClick = (status: string, templateSheetBenefitIds: string, selYear: any) => {
        setIsSaveSiteSubmitted(true);
        let alertMessage: string = status === DraftStatus ? 'Saved successfully' : status +' '+ selYear + " Site configuration successfully";
        submitOrApproveSiteConfigurations(status, templateSheetBenefitIds, alertMessage, true,selYear);
        setIsSaveSiteSubmitted(false);
    }

    // Adds or updates the review statuses.
    const addOrUpdateReviewStatuses = (groupName: string, reviewStatus: StatusModel) => {
        allItemsReviewed();
        setReviewStatuses((prevState) => ({
            ...prevState,
            [groupName]: {
                templateSheetBenefitId: reviewStatus?.templateSheetBenefitId,
                isReviewed: reviewStatus?.isReviewed,
                benefitYear: reviewStatus.benefitYear
            }
        }));
        setIsAcknowledged(false);
    }

    // Ensures benefit years are available and sets the selected benefit year from local storage .
    const fetchOrSetBenefitYears = () => {
        const selectedYear = GetKeyValueFromLocalStorage(SelectedYear)  && JSON.parse(GetKeyValueFromLocalStorage(SelectedYear));
        const benefitYearsFromLocalStorage = GetKeyValueFromLocalStorage(BenefitYears)  && JSON.parse(GetKeyValueFromLocalStorage(BenefitYears));
        if (!selectedYear || !benefitYearsFromLocalStorage || benefitYearsFromLocalStorage.length === 0) {
            getBenefitYearsForACarrier();
        } else {
            setSelectedBenefitYearFromLocalStorage(benefitYearsFromLocalStorage, selectedYear);
        }
    };

    // Sets the selected benefit year based on BenefitYear from local storage.
    const setSelectedBenefitYearFromLocalStorage = (benefitYears: any, selectedYear: any) => {
        let benefitYear = GetKeyValueFromLocalStorage(BenefitYear) && Number(GetKeyValueFromLocalStorage(BenefitYear));
        let selectedBenefitYear = selectedYear;
        if (benefitYear && benefitYear !== undefined) {
            selectedBenefitYear = benefitYears.find((currentYear:any) => Number(currentYear.benefitYear) === benefitYear);
        }
        updateBenefitYear(selectedBenefitYear);
        SetKeyToLocalStorage(SelectedYear, JSON.stringify(selectedBenefitYear));
    };



    // Use useEffect to set the default active tab below 991px
    useEffect(() => {
        allItemsReviewed();
        fetchOrSetBenefitYears();
        const handleResize = () => {
            const isBelow992 = window.innerWidth < 992;
            if (isBelow992) {
                setActiveTab('Plans');
            }
        };

        handleResize(); // Call the function on initial load
        window.addEventListener('resize', handleResize); // Add event listener for window resize

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Component mount and unmount of page life cycle.
    useEffect(() => {
        var currentActiveTab = GetKeyValueFromLocalStorage(CarrierDetailsActiveTab);
        if (currentActiveTab?.toString()?.length > 0) {
            setActiveTab(currentActiveTab);
        }
        else {
            setActiveTab('Plans');
        }
    })

    // State variable to hold the radio buttton value that is selected in plan override popup.
    const [selectedOption, setSelectedOption] = useState<string | null>(null);


    // Handles OverRides button change of plan override popup.
    const handleButtonChange = (value: any) => {
        setIsOverRidePrefered(null);
        setSelectedOption(value);
    };

    const handleIacknowledgeChange = () => {
        if (!isSecondModalVisible) {
            setSelectedOption("plansWithoutOverrides");
        }
    };

    // Handles to close popup of plan override popup.
    const closePopup = () => {
        setIsSecondModalVisible(false);
        setSelectedOption(null);
    }

    // Handles the update for benefit year based on the selected year in the plans page.
    const updateBenefitYear = (selectedYearData: any) => {
        setSelectedData(selectedYearData);
        activeInsuranceCarrier.benefitYear = selectedYearData?.benefitYear;
    }
    
    const isButtonDisabled = !selectedOption;

    // Handles apply button in plan override popup.
    const handleApplyButtonClick = async (selYear: any) => {
        try {
            if (isSecondModalVisible) {
                let answerValue = selectedOption?.toLowerCase() === OverrideButton?.toLowerCase() ? 'Yes' : 'No';
                let UserName = GetKeyValueFromLocalStorage("username") ?? ''
                // Call the InsertIsPlanOverrides POST API method with parameters directly
                const apiUrl = `${InsertIsPlanOverrides}/${activeInsuranceCarrier.insuranceCarrierId}/${selYear}/${answerValue}/${UserName}`;
                const response = await client.post(apiUrl);
                // Added approve condition so only admin role can OverRide the details.
                if (response.status === 200 && answerValue?.toLowerCase() === 'yes' && (getSelectedRole()?.toString()?.toLowerCase() === Admin?.toString()?.toLowerCase()
                    || getSelectedRole()?.toString()?.toLowerCase() === SystemAdmin?.toString()?.toLowerCase())) {

                    // Prepare data for the OverrideCatchallDataPlanAnswers POST API method
                    const requestBody2 = {
                        InsuranceCarrierId: activeInsuranceCarrier?.insuranceCarrierId,
                        BenefitYear: selYear,
                        UserName: GetKeyValueFromLocalStorage("username") ?? ''                        
                    };

                    // Call the second OverrideCatchallDataPlanAnswers API method
                    const isOverRideResponse = await client.post(OverrideCatchallDataPlanAnswers, requestBody2);
                    if (isOverRideResponse) {
                        setSaveSiteConfigurationAlert(true);
                        setSaveSiteConfigurationAlertMessage('Approved Site configuration successfully');
                        setTimeout(() => { setSaveSiteConfigurationAlert(false); }, 3000);
                    }
                    setIsSaveSiteSubmitted(false);
                    closePopup();
                }
                else if (response.status === 200) {
                    onSubmitOrApproveBtnClick(getBtnTitle(), Object.keys(reviewStatuses || []).map((key: string) => reviewStatuses[key].templateSheetBenefitId).join(','), selYear);
                }
            } else {
                onSubmitOrApproveBtnClick(getBtnTitle(), Object.keys(reviewStatuses || []).map((key: string) => reviewStatuses[key].templateSheetBenefitId).join(','), selYear);
            }
        }
        catch (error) {
            setSaveSiteConfigurationAlert(false);
            console.error('Error:', error);
        } finally {
            
            closePopup();
        }
    };


    // Removes the year related keys from local storage.
    const RemoveYearsInLocalStorage = () => {
        localStorage.removeItem(SelectedYear);
        localStorage.removeItem(BenefitYears);
        localStorage.removeItem(BenefitYear);
    }

    return <div className="d-lg-flex overflow-hidden">
        <div className={loading === true ? "loading" : ""}></div>
        <Alert className={saveSiteConfigurationAlert ? '' : 'd-none'} variant="success" dismissible>
            <span className="alert-left d-flex align-items-center">
                <img
                    className="img-fluid alert-icon me-2"
                    src={AlertSuccess}
                    width={18}
                    height={18}
                    alt=" "
                />
                <span>{saveSiteConfigurationAlertMessage}</span>
            </span>
        </Alert>
        <SideBar />

        <div className="page-content">
            <div className='page-title-section'>
                <div className="page-top-left">
                    <Breadcrumb className="breadcrumbs d-none d-lg-block">
                        <Breadcrumb.Item onClick={navigateToCarrierPage} className="cursor-pointer">Carriers</Breadcrumb.Item>
                        <Breadcrumb.Item active>{activeInsuranceCarrier?.insuranceCarrierName} </Breadcrumb.Item>
                    </Breadcrumb>
                    <h3><a href="/carriers"><img className="img-fluid" src={BackArrow} onClick={RemoveYearsInLocalStorage} alt=" " /></a> <span title={activeInsuranceCarrier?.insuranceCarrierName}>{activeInsuranceCarrier?.insuranceCarrierName}</span><span className={`status ms-2 ${status} d-none d-lg-inline-block`}>{selectedData?.status}</span></h3>
                </div>
                <div className='page-top-right'>
                    <div className="page-top-actions">
                        <Button className="btn-outline-primary upload d-none">
                            <img className="img-fluid" src={Upload} alt=" " />
                        </Button>
                        <Dropdown className="ms-3 d-none d-lg-inline-block">
                            <Dropdown.Toggle variant="secondary" id="dropdown-menu-align-start" className="m-0">
                                <img className="img-fluid me-2" src={SubmitIcon} alt=" " /><span>{btnTitle}</span><img className="img-fluid arrow ms-1" src={DownArrow} alt=" " />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {!isbuttonEnabled ?
                                    <Dropdown.Item
                                        disabled={(btnTitle === Submit ? isSubmitted : btnTitle === Approve ? isApprovedOrPublished : true)}
                                        onClick={handleNewPopUp}>
                                        {btnTitle === Approve ? `Approve ${year} Site Configuration` : `Submit  ${year} Site Configuration`}
                                        {/*{activeTab === "SiteConfigurations" ? <span className="status review">Current Page</span> : <></>}*/}
                                    </Dropdown.Item> :

                                    <Dropdown.Item
                                        disabled={(btnTitle === Submit ? isSubmitted : btnTitle === Approve ?  isApprovedOrPublished  : true)}
                                        onClick={handleNewPopUp}>
                                        {btnTitle === Approve ? `Approve ${year + 1} Site Configuration` : `Submit  ${year + 1} Site Configuration`}
                                    </Dropdown.Item>
                                }
                            {/*    <Dropdown.Item href="#"><span>{btnTitle === Approve ? 'Approve Physical Catalogs' : 'Submit Physical Catalogs'}</span></Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="last-updated">
                        <p><span>Last Updated:</span> <span>Apr 12, 2023</span></p>
                        <span className="time-zone show-popup show-tooltip plan-icon">
                            <img className="img-fluid" src={Time} alt=" " /><span>EST</span>
                            <span className="hover-popup left">The timings shown in the website are in Eastern Time Zone.</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="tabslist-main">
                <Tabs id="uncontrolled-tab-example"
                    className="tabs-list"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}>
                    <Tab eventKey="CarrierSettings" title="Carrier Settings">
                        {activeTab === "CarrierSettings" && (
                            <CarrierSettings activeTab={activeTab} activeInsuranceCarrier={activeInsuranceCarrier} />
                        )}
                    </Tab>
                    <Tab eventKey="SiteConfigurations" title={
                        <>
                            {isDraft ? <></>
                                : (isAtleastOneSubmitted || isSubmitted) && !isApprovedOrPublished ? <InProgressIcon/>
                                    : isApprovedOrPublished ? <ApprovedIcon/>
                                        : <></>
                            }
                            <div>Site Configurations</div>
                            {isFutureConfig ? <FutureConfigIcon isHoverAlignedLeft={ false} /> : <></>}
                        </>
                    }>
                        <SiteConfig
                            setReviewStatuses={(groupName: string, reviewStatus: StatusModel,benefitYear:number) => {
                                addOrUpdateReviewStatuses(groupName, reviewStatus);
                                changeStatusToReviewedOrDraft(groupName, reviewStatus?.isReviewed ? ReviewedStatus : DraftStatus, [reviewStatus.templateSheetBenefitId].join(','), reviewStatus.benefitYear);
                            }}
                            changeStatusToDraft={async (groupName: string, templateSheetBenefitId: string,benefitYear:number): Promise<boolean> => {
                                return changeStatusToReviewedOrDraft(groupName, DraftStatus, templateSheetBenefitId, benefitYear);
                            }}
                            updateReviewStatuses={(groupName: string, reviewStatus: StatusModel) => {
                                addOrUpdateReviewStatuses(groupName, reviewStatus)
                            }}
                            setIsSaveSubmitted={(isSubmit: boolean) => { setIsSaveSiteSubmitted(isSubmit) }}
                            btnTitle={getBtnTitle()}
                            refreshSiteConfigurations={refreshSiteConfigurations}
                            setIsApprovedOrPublished={(isApprovedOrPublished: boolean) => { setIsApprovedOrPublished(isApprovedOrPublished) }}
                            setIsDraft={(isDraft: boolean) => { setIsDraft(isDraft) }}
                            setIsSubmitted={(isSubmitted: boolean) => { setIsSubmitted(isSubmitted) }}
                            setIsButtonEnabled={(isButtonEnabled: boolean) => { setIsbuttonEnabled(isButtonEnabled) }}
                            setIsAtleastOneSubmitted={((isAtleastOneSubmitted: boolean) => { setAtleastOneSubmitted(isAtleastOneSubmitted) })}
                            activeTab={activeTab}
                            activeInsuranceCarrier={activeInsuranceCarrier}
                            planDetails={planDetails}
                            setLoading={(load: boolean) => setLoading(load)}
                            setIsFutureSiteConfigAvailable={(isfutureConfig: boolean) => { setIsFutureConfig(isfutureConfig) }}
                                catchAll="true" />
                    </Tab>
                    <Tab eventKey="Plans" title="Plans">
                        {selectedData?.benefitYear !== 0 && activeTab === "Plans" &&(
                            <Plans activeTab={activeTab} activeInsuranceCarrier={activeInsuranceCarrier} updateBenefitYear={(year: number) => updateBenefitYear(year)} />
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div>
        {/* Submit Carrier Modal Popup */}
            <Modal show={isSaveSiteSubmitted} backdrop="static" keyboard={false} size="lg" centered className="">
                <Modal.Header closeButton onClick={() => { setIsAcknowledged(false); setIsSaveSiteSubmitted(false); closePopup(); }}>
                    <Modal.Title>{btnTitle} Carrier - Site Configuration for {selectedYear}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Checklist before submission</h5>
                    <p>Make sure you have filled the necessary fields and reviewed the information.</p>
                    <ListGroup className="modal-list">
                        {Object.keys(reviewStatuses || [])?.map((key: any, index: number) => {
                            return <ListGroup.Item> <img className="img-fluid" src={reviewStatuses[key]?.isReviewed ? GreenTick : RedCross} alt=" " />Verified {key}</ListGroup.Item>
                        })}
                    </ListGroup>
                    <div className="modal-check"><Form.Check checked={isAcknowledged}
                        disabled={Object.values(reviewStatuses).some(status => !status.isReviewed)} aria-label="option 1" className="form-check" onClick={() => { setIsAcknowledged(!isAcknowledged); handleIacknowledgeChange(); }} /><span className="acknowledge">I acknowledge that I have reviewed all the information</span></div>

                    {isSecondModalVisible ?
                        <div>
                            <div className="plans-overrides">
                                <p>There are plans in this carrier with site configuration overrides.<br />Apply these changes to:</p>
                                <div className="d-flex align-items-center mt-3">
                                    <button id="plansWithoutOverrides"
                                        name="applyToPlans"
                                        value="plansWithoutOverrides"
                                        type="button"
                                        disabled={!isAcknowledged}
                                        className=
                                    {`btn-outline  btn btn-primary ${isOverRidePrefered === false ? 'active' : (isOverRidePrefered === true || isOverRidePrefered === null) ? selectedOption?.toLowerCase() === WithOutOverrideButton?.toLowerCase() ? 'active' : '': ''}`}
                                        onClick={() => { handleButtonChange("plansWithoutOverrides"); }}
                                    >Plans without overrides only</button>
                                    <button id="allPlans"
                                        name="applyToPlans"
                                        value="allPlans"
                                        disabled={!isAcknowledged}
                                        type="button"
                                        className=
                                    {`btn-outline  btn btn-primary ${isOverRidePrefered === true ? 'active' : (isOverRidePrefered === false || isOverRidePrefered === null) ? selectedOption?.toLowerCase() === OverrideButton?.toLowerCase() ? 'active' : '': ''}`}
                                        onClick={() => { handleButtonChange("allPlans"); }}
                                    >All plans including plans with overrides</button>
                                </div>
                            </div>
            </div>:<div></div>
                    }
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button className="btn-secondary" disabled={!isAcknowledged || isButtonDisabled} onClick={() => {
                        handleApplyButtonClick(selectedYear);
                        /* onSubmitOrApproveBtnClick(getBtnTitle(), Object.keys(reviewStatuses || []).map((key: string) => reviewStatuses[key].templateSheetBenefitId).join(','));*/
                    }}>{btnTitle}</Button>
                    <Button className="btn-outline-primary" onClick={() => { setIsSaveSiteSubmitted(false); setIsAcknowledged(false); closePopup(); }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
    </div>

} 