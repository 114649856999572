/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FloatingLabel, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BenefitsProURL, EH_MyBenefitsURL, GetCarrierSubdomain, GetInsertRedirectEnvlope, GetMemberInformation, GetMultiTenantToken, MyBenefitsURL, getEnvironment, getEnvironmentUrl, getMEPUIEnvironmentUrl, resolution, undefinedConst, year } from '../../Constants/AppConstants';
import { IExistingMembers, MemberInformation, TokenInformation } from '../../Interfaces/InsuranceHealthPlans';
import calendarIcon from "../../images/calendar-icon.svg";
import ExternalGrey from "../../images/external-blue.svg";
import ExternalWhite from "../../images/external-white.svg";
import Search from "../../images/search-small.svg";
import '../../scss/status.scss';
import '../../scss/table.scss';
import '../../scss/tabs.scss';
import client from "../../utils/ApiClient";
import './BenefitConfig.scss';
import './BenefitQuestions.scss';
import './Carriers.scss';
import './StickyNav.scss';

interface EmulateMemberProps {
    onClose: () => void; // Prop to close the modal
}
// Carriers.
export const EmulateMember: React.FC<EmulateMemberProps> = ({ onClose }) => {

    //MemberInformation
    let memberDetails: MemberInformation = {
        healthPlanId: "",
        carrierId: "",
        nhMemberId: "",
        memberInsuranceID: "",
        memberId: "",
        userName: "",
        effectiveDate: "",
        firstname: "",
        lastName: "",
        ssn: "",
        dateOfBirth: "",
        isEmulatorMode: false,
        insuranceNbr: "",
        success: true
    }

    // State variable for sub domain.
    const [subDomain, setSubDomain] = useState<string | null>(null);

    // State variable for Loader.
    const [loader, setLoader] = useState(true);

    // State variable for member info.
    const [memberInfo, setMemberInfo] = useState<MemberInformation>(memberDetails);

    // State variable to show activity popup.
   // const [showMemberSearch, setShowMemberSearch] = useState(false);

    const [insuranceCarrierid, setInsuranceCarrierid] = useState<string>();

    /// State variable for redirect token.
    const [redirectToken, setRedirectToken] = useState('');

    /// State variable for multitenant token.
    const [multiTenantToken, setMultiTenantToken] = useState('');
    // State variable for preview date.
    const [previewDate, setPreviewDate] = useState<string>(moment(new Date()).format("MM-DD-YYYY"));
    // Initialize with current screen width getTokenInformation();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    // Instance.
    const { instance } = useMsal();

    // Accounts.
    const accounts = instance.getAllAccounts();

    // Login user name.
    const loggedInUserName = accounts[0]?.username.split("@")[0]?.toLowerCase();

    //List of existing members
    const [listOfExistingMembers, setListOfExistingMembers] = useState<IExistingMembers[]>();

    //State varible for ExistingMembers
   // const [existingMembers, setExistingMembers] = useState(false);
    // State variable for loader.
    const [isLoading, setIsLoading] = useState(false);

    //State varible for isValidMember
    const [isValidMember, setIsValidMember] = useState(true);
    // State variable for username.
    const [userName, setUserName] = useState<string>('');

    //State varible for isMemberEntered
    const [isMemberEntered, setIsMemberEntered] = useState(true);
    //State varible for searchedMemberInfo
    const [searchedMemberInfo, setSearchedMemberInfo] = useState<string>('');

    // State variable for show date picker.
    const [showDatePicker, setShowDatePicker] = useState(false);

    //Handle key down
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {

            if (!!searchedMemberInfo) {
                onClickHandler();
            }
            else {
                setIsMemberEntered(false);
            }
        }
        else if (event.key === "Backspace" || event.key === "Delete") {
            setRedirectToken('');
            setListOfExistingMembers([]);
        }
    };
    //search Member Info
    const getSearchMemberInfo = (e: any) => {
        setSearchedMemberInfo(e.target.value);
    }

    //Get Token Information
    const getTokenInformation = () => {
        const obj: TokenInformation = {
            NHMemberId: memberInfo.nhMemberId,
            CarrierId: memberInfo.carrierId,
            HealthPlanId: memberInfo.healthPlanId,
            memberInsuranceID: memberInfo.memberInsuranceID,
            MemberId: memberInfo.memberId,
            UserName: memberInfo.userName,
            EffectiveDate: moment(previewDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
            Firstname: memberInfo.firstname,
            Lastname: memberInfo.lastName,
            SSN: memberInfo.ssn,
            DateOfBirth: memberInfo.dateOfBirth,
            IsEmulatorMode: true,
            insuranceNbr: memberInfo.insuranceNbr,
            loggedInUserName: loggedInUserName
        }


        const addBenefitRequest = {
            envelop: JSON.stringify(obj)
        }

        client.post(GetInsertRedirectEnvlope, addBenefitRequest)
            .then((response) => {

                setRedirectToken(response?.data?.redirectionToken);
                //console.log(response?.data?.redirectionToken)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            })



    }

    const startsWithNhOrEh = (text: string) => {
        // Convert the input text to lowercase for case-insensitive comparison
        const lowercaseText = text.toLowerCase();

        // Check if the text starts with "nh" or "eh"
        if (lowercaseText.startsWith("nh") || lowercaseText.startsWith("eh")) {
            return true;
        } else {
            return false;
        }
    }
    const startsWithNhOrEhNumber = (text: string) => {
        // Convert the input text to lowercase for case-insensitive comparison
        const lowercaseText = text.toLowerCase();

        // Check if the text starts with "nh"
        if (lowercaseText.startsWith("nh")) {
            return 1;
        } else {
            return 2;
        }
    }

    // Benfits pro url
    const MyBenefitsProURL =
        startsWithNhOrEhNumber(searchedMemberInfo) === 2
            ? EH_MyBenefitsURL
            : MyBenefitsURL;
    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        if (!!memberInfo.nhMemberId && !!previewDate && memberInfo.success)
            getTokenInformation();

    }, [memberInfo.nhMemberId, previewDate])

    // To get the multitenant token.
    const getTenantToken = async () => {
        try {
            const tenantBody = {
                tenantCode: getEnvironment(startsWithNhOrEhNumber(searchedMemberInfo)).toString()
            };
            const response = await client.post(GetMultiTenantToken, tenantBody);
            const token = response?.data?.accessToken;
            setMultiTenantToken(token);
            return token;
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            return null;
        }
    };


    const onClickHandler = async () => {

        setIsLoading(true);
        let isNH: boolean = startsWithNhOrEh(searchedMemberInfo);
        let memberData = {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            insuranceCarrierId: 0,
            nhMemberId: isNH ? searchedMemberInfo : '',
            phoneNumber: 0,
            address: "",
            state: "",
            zipCode: "",
            poNumber: 0,
            orderId: 0,
            city: "",
            email: "",
            otcCardNumber: "",
            insuranceMemberId: isNH ? "" : searchedMemberInfo,
            searchType: ""
        }
        const tenantToken = await getTenantToken();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'user-authorization': tenantToken,
            }
        }
        client.post(getEnvironmentUrl(startsWithNhOrEhNumber(searchedMemberInfo)), memberData, config)
            .then((response) => {
                let data: IExistingMembers[] = response?.data?.result;                
                if (data.length > 0) {
                    setIsValidMember(true);
                    setIsMemberEntered(true);


                } else {

                    setRedirectToken('');
                    setMemberInfo(memberDetails);
                    setIsValidMember(false);
                }
                setInsuranceCarrierid(response?.data?.result[0]?.insuranceCarrierID);
                setListOfExistingMembers(response?.data?.result);
                getSubdomain(response?.data?.result[0]?.insuranceCarrierID, 0);
                setIsLoading(false);

            })
            .catch((error) => {
                setIsLoading(false);
                setIsValidMember(false);
            }).finally(() => {
                const sectionElement = document.getElementById(`previewDateId`);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: "smooth" });
                }
            })
        getTokenInformation();
    }
    // Function to update the screen width state on window resize
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };
    // Add a resize event listener to update the screen width state
    useEffect(() => {
        window.addEventListener("resize", updateScreenWidth);
        return () => window.removeEventListener("resize", updateScreenWidth);
    }, []);

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        const storedUserName = localStorage.getItem("userId");
        if (!!storedUserName && storedUserName?.toLowerCase() !== undefinedConst)
            setUserName(storedUserName);
    }, [])

    const getMemberInformation = (nHMemberId: string) => {
        //setIsLoading(true);
        client.get(`${GetMemberInformation}/${nHMemberId}/${startsWithNhOrEhNumber(nHMemberId)}`)
            .then((response) => {
                let index = 0;
                index = response?.data?.data?.memberInsurances.findIndex((e: any) => {
                    return (e?.insuranceCarrierID === insuranceCarrierid);
                });
                if (index === -1) {
                    index = 0;
                }
                if (response?.status === 200) {
                    setMemberInfo((prev: MemberInformation) => ({
                        healthPlanId: '0',
                        carrierId: response?.data?.data?.memberInsurances[index]?.clientCarrierID,
                        nhMemberId: nHMemberId,
                        memberInsuranceID: response?.data?.data?.memberInsurances[index]?.insuranceDetail?.memberInsuranceID,
                        memberId: response?.data?.data?.memberID,
                        userName: userName,
                        effectiveDate: previewDate,
                        firstname: response?.data?.data?.firstName,
                        lastName: response?.data?.data?.lastName,
                        ssn: response?.data?.data?.ssnLast4,
                        dateOfBirth: moment(response?.data?.data?.dateOfBirth).format('YYYY-MM-DD'),
                        isEmulatorMode: true,
                        insuranceNbr: response?.data?.data?.memberInsurances[index]?.insuranceDetail?.insuranceNbr,
                        success: true
                    }));

                }
                else {
                    setMemberInfo((prev: MemberInformation) => ({
                        ...prev,
                        success: false
                    }));
                    setRedirectToken('');
                    setIsLoading(false);
                }

            })
            .catch((error) => {
                setIsLoading(false);
                setMemberInfo((prev: MemberInformation) => ({
                    ...prev,
                    success: false
                }));
                setRedirectToken('');
            }).finally(() => {
                const sectionElement = document.getElementById(`previewDateId`);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: "smooth" });
                }
            })

    }



    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        if (!!searchedMemberInfo) {
            getMemberInformation(searchedMemberInfo);
        }
    }, [searchedMemberInfo])

    // Gets the subdomain based on carrierid,benefitYear,healthPlanId
    const getSubdomain = (insuranceCarrierId: number, insuranceHealthPlanId: number) => {
        //Get Subdomain
        client.get(`${GetCarrierSubdomain}/${insuranceCarrierId}/${insuranceHealthPlanId}`)
            .then((response) => {
                setSubDomain(response?.data[0]);

            }).catch((error) => {
            }).finally(() => {
            })
    }
   
    return (
        <div className={`d-flex overflow-hidden ${(loader === true ? "loading-skeleton" : "")}`}>
            <Modal className="modal-large bottom filters-modal" show={true} backdrop="static" keyboard={false} size="xl" centered>
                {isLoading && <div className="loading"></div>}
                <Modal.Header closeButton onClick={onClose}>
                    <Modal.Title>Emulate Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="semect-member">
                        <div className="semect-member">
                            <div className="border-list">
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <div className="form-field table-search features-search w-100 mw-100">
                                            <FloatingLabel controlId="floatingInput"
                                                label={`Search By NH/EH MemberID or Insurance Member ID*`}
                                                className="m-0">
                                                <input
                                                    className="form-control search-input w-100"
                                                    placeholder="Search Plans"
                                                    aria-label="Search"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-display="static"
                                                    aria-expanded="false"
                                                    onChange={getSearchMemberInfo}
                                                    onKeyDown={handleKeyDown}
                                                    value={searchedMemberInfo}

                                                />
                                                <Image
                                                    src={Search}
                                                    className="form-field-icon"
                                                    alt=""
                                                    onClick={() => {
                                                        if (!!searchedMemberInfo) onClickHandler();
                                                        else setIsMemberEntered(false);
                                                    }}
                                                />
                                            </FloatingLabel>
                                            {!isMemberEntered && (
                                                <h6 className="error-message"> NH Member Id or Insurance Member Id cannot be empty</h6>
                                            )}
                                            {!isValidMember && (
                                                <h6 className="error-message">No Members Found , Please do accurate search</h6>
                                            )}
                                            {listOfExistingMembers && listOfExistingMembers.length > 1 && (
                                                <h6 className="error-message">Multiple matches found with the search criteria.</h6>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    {listOfExistingMembers && listOfExistingMembers.length > 0 && (!!searchedMemberInfo) && (
                                        <>
                                            <h6 className="date mt-4">
                                                Member Details <sup>*</sup>
                                            </h6>
                                            <p className="mb-0">
                                                Name :{" "}
                                                {listOfExistingMembers[0]?.firstName + " " + listOfExistingMembers[0]?.lastName}
                                            </p>
                                            <p>
                                                {getEnvironment(startsWithNhOrEhNumber(searchedMemberInfo))} Member ID:{" "}
                                                {listOfExistingMembers[0]?.nhMemberId}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 className="date">Select Future Preview Date <sup>*</sup></h6>
                    <p>
                        Date at which you want the test user to be.{" "}
                        <br className="d-none d-lg-block" />
                        {`Must be after 12/31/${year - 1} and before 1/1/${year + 1}.`}
                    </p>
                    <Row>
                        <Col xs={12} lg={6}>
                            {

                            }
                            <FloatingLabel
                                controlId="floatingInput"
                                label={`${previewDate === '' ? "Select Future Preview Date" : ""}`}
                                className={`${previewDate === '' ? "required " : ""}  m-0`}

                            >
                                <DatePicker
                                    //  ref={datePickerRef}
                                    className='form-control w-100'
                                    selected={previewDate ? moment(previewDate, 'MM-DD-YYYY').toDate() : null}
                                    onChange={(date) => {
                                        if (date) {
                                            let dt = "";
                                            dt = moment(date).format("MM-DD-YYYY");
                                            if (!!memberInfo.nhMemberId) {
                                                setIsLoading(true);
                                            }

                                            setPreviewDate(dt);
                                            setShowDatePicker(false);
                                        }
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText=""
                                    dateFormat="MM-dd-yyyy"
                                    onInputClick={() => { setShowDatePicker(true); }}
                                    onBlur={() => { setShowDatePicker(false); }}
                                    open={showDatePicker}
                                    popperPlacement='top'


                                > </DatePicker>
                                <Image src={calendarIcon} className="form-field-icon" onClick={() => setShowDatePicker(!showDatePicker)} />

                            </FloatingLabel>


                        </Col>
                    </Row>
                    <div id="previewDateId" ></div>
                </Modal.Body>

                <Modal.Footer className="justify-content-start">
                    <Button className="btn-secondary " disabled={!(!!redirectToken) ? true : !(!!subDomain) ? true : false}
                        href={screenWidth >= resolution ? `https://${subDomain}.${MyBenefitsProURL}/emulator?redirectToken=${redirectToken}` : `${BenefitsProURL}?RedirectToken=${redirectToken}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {"Benefits Pro Portal "}
                        <img className="img-fluid ms-2" src={ExternalWhite} alt=" " />
                    </Button>

                    <Button className="btn-outline-primary" disabled={!(!!redirectToken) ? true : !(!!userName) ? true : false}
                        href={`${getMEPUIEnvironmentUrl(startsWithNhOrEhNumber(searchedMemberInfo))}/emulator?RedirectToken=${redirectToken}`}
                        rel="noopener noreferrer"
                        target="_blank">
                        Launch MEP{" "}
                        <img className="img-fluid ms-2" src={ExternalGrey} alt=" " />
                    </Button>
                    {/*<Button className="btn-secondary "*/}
                    {/*>*/}
                    {/*    {"Agent Login "}*/}
                    {/*    <img className="img-fluid ms-2" src={ExternalWhite} alt=" " />*/}
                    {/*</Button>*/}
                </Modal.Footer>

            </Modal>

        </div>
    );
};