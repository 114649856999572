import './Notifications.scss';
import Image from 'react-bootstrap/Image';
import NotificationIcon from "../../images/notification-add.svg";
import { SideBar } from '../SideBar';

export const AvailableNotifications = () => {
    return (
        <>
            <SideBar />
            <div className="d-flex overflow-hidden notification-page">
                <div className="page-content">
                    <div className='page-title-section'>
                        <h3>Notification Management</h3>
                    </div>

                    <div className="page-content-bottom notifications-container">
                        <h5>Available Notifications</h5>
                        <p className="sub-text">Please choose the configuration you want to make</p>
                        <div className="notifications-flex">
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Verification Code (Registration, 2FA)</span>
                            </div>
                            <div className="notification-card" style={{
                                backgroundColor: "#d0e2ff",
                                border: "2px solid #007bff"
                            }} onClick={() => window.location.href = "/Notifications"}>
                                <div className="icon" >
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Benefit Load</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Benefit Expiration</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Catalog Delivery</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Order Confirmation</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Order Shipped</span>
                            </div>

                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Order On Its Way</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Order Delivered</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Order Cancelled</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Retail Transaction Approved</span>
                            </div>
                            <div className="notification-card">
                                <div className="icon">
                                    <Image src={NotificationIcon} className="form-field-icon" alt="" />
                                </div>
                                <span>Retail Transaction Denied</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}