/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../src/scss/common.scss";
import "../src/scss/accordion.scss";
import "../src/scss/alert.scss";
import "../src/scss/breadcrumb.scss";
import "../src/scss/buttons.scss";
import "../src/scss/dropdown.scss";
import "../src/scss/form-control.scss";
import "../src/scss/modal.scss";
import "../src/scss/page-title.scss";
import "../src/scss/table.scss";
import "../src/scss/tooltip.scss";
import "../src/scss/skeleton.scss";
import "../src/scss/progress.scss";
import { Features } from "./components/Features/Features";
import { Home } from "./components/Home/Home";
import { Login } from "./components/Login/Login";
import { Carriers } from "./components/Carriers/Carriers";
import { Review } from "./components/Review/Review";
import { CarrierDetails } from "./components/Carriers/CarrierDetails";
import { PlanDetails } from "./components/Carriers/PlanDetails";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AzureLoginIDKey, BenefitYears, GetMemberuserInfo, SESSION_TIMEOUT, SelectedYear, UserRoleKey } from "./Constants/AppConstants";
import { AppProvider } from "./context/AppContext";
import { AccessDenied } from "./components/AccessDenied/AccessDenied";
import client from "./utils/ApiClient";
import { FutureSiteConfig } from "./components/Carriers/FutureSiteConfig";
import { CallbackProvider } from "./context/CallbackContext";
import { DataProvider } from "./context/DataContext";
import { Notifications } from "./components/Notifications/Notifications";
import { AvailableNotifications } from "./components/Notifications/AvailableNotifications";
import { PlanNotifications } from "./components/Notifications/PlanNotifications";

function App() {
    const { instance, accounts } = useMsal();

    // Timer reference.
    const timerRef = useRef<any>(null);

    const isAuthenticated = useIsAuthenticated();

    const tokenRequest = {
        scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/UserImpersonation`],
    };

    const getAccessToken = () => {
        instance
            .acquireTokenSilent({
                ...tokenRequest,

                account: accounts[0],
            })
            .then((response: any) => {
                localStorage.removeItem(AzureLoginIDKey);
                localStorage.setItem(AzureLoginIDKey, accounts[0]?.name ?? '')
                localStorage.removeItem("accesstoken");
                localStorage.setItem("accesstoken", response.accessToken);
                client.get(`${GetMemberuserInfo}?userName=${accounts[0]?.username}`)
                    .then((response) => {
                        localStorage.removeItem("userId");
                        localStorage.setItem("userId", response?.data?.result?.userName);
                    }).catch((error) => {
                    }).finally(() => {
                    })
            })
            .finally(() => { });
    };

    // Function to handle user logout and redirect to the Microsoft login page
    const logoutAndRedirectToLogin = () => {
        localStorage.removeItem(UserRoleKey);
        localStorage.removeItem(SelectedYear);
        localStorage.removeItem(BenefitYears);
        localStorage.removeItem("accesstoken");
        window.location.href = "/";
        instance.logout({
            onRedirectNavigate: () => {
                return false;
            }
        });
    };

    // Starts the session timer.
    const startSessionTimer = () => {
        timerRef.current = window.setTimeout(logoutAndRedirectToLogin, SESSION_TIMEOUT);
    }

    // Reset the session timeout timer on user activity
    const resetSessionTimer = () => {
        window.clearTimeout(timerRef.current);
        startSessionTimer();
    };

    useEffect(() => {
        if (isAuthenticated) {
            getAccessToken();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // Add event listeners for 'mousemove' and 'keydown' to reset the session timer
        const handleMousemove = () => resetSessionTimer();
        const handleKeydown = () => resetSessionTimer();

        document.addEventListener('mousemove', handleMousemove);
        document.addEventListener('keydown', handleKeydown);

        // Start the initial session timer
        startSessionTimer();

        // Clean up event listeners on unmount
        return () => {
            document.removeEventListener('mousemove', handleMousemove);
            document.removeEventListener('keydown', handleKeydown);

            // Clear the session timer when unmounting the component
            if (timerRef) {
                window.clearTimeout(timerRef.current);
            }
        };
    }, []); // Empty dependency array to run only once on mount


    return (
        <BrowserRouter>
            <AuthenticatedTemplate>
                <AppProvider>
                    <CallbackProvider>
                        <DataProvider>
                            <Routes>
                                <Route path={RoutePaths.Login} element={<Login handleRoleSelection={() => { }} userRoles={[]} />} />
                                <Route path={RoutePaths.Home} element={<Home />} />
                                <Route path={RoutePaths.Features} element={<Features />} />
                                <Route path={RoutePaths.Carriers} element={<Carriers />} />
                                <Route path={RoutePaths.AccessDenied} element={<AccessDenied />} />
                                <Route path={RoutePaths.CarrierDetails} element={<CarrierDetails />} />
                                <Route path={RoutePaths.PlanDetails} element={<PlanDetails />} />
                                <Route path={RoutePaths.Review} element={<Review />} />
                                <Route path={RoutePaths.FutureSiteConfig} element={<FutureSiteConfig />} />
                                <Route path={RoutePaths.Notifications} element={<Notifications />} />
                                <Route path={RoutePaths.AvailableNotifications} element={<AvailableNotifications />} />
                                <Route path={RoutePaths.PlanNotifications} element={<PlanNotifications />} />
                            </Routes>
                        </DataProvider>
                    </CallbackProvider>
                </AppProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Routes>
                    <Route path={RoutePaths.Home} element={<Home />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </UnauthenticatedTemplate>
        </BrowserRouter>
    );
}
export enum RoutePaths {
    Login = "/Login",
    Home = "/",
    Features = "/Features",
    Carriers = "/Carriers",
    CarrierDetails = "/CarrierDetails",
    PlanDetails = "/PlanDetails",
    Review = "/Review",
    AccessDenied = "/AccessDenied",
    FutureSiteConfig = "/FutureSiteConfig",
    Notifications = "/Notifications",
    AvailableNotifications = "/AvailableNotifications",
    PlanNotifications = "/PlanNotifications"
}

export default App;
