import './Notifications.scss';
import Image from 'react-bootstrap/Image';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DownArrow from "../../images/down-arrow.svg";
import Filter from "../../images/filters-primary.svg";
import Search from "../../images/search-small.svg";
import CrossIcon from "../../images/close_blue.svg";
import CheckIcon from "../../images/check_white.svg";
import Scrollbars from 'rc-scrollbars';
import Revoke from "../../images/revoke.svg";
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, FloatingLabel, Modal } from 'react-bootstrap';
import { SideBar } from "../SideBar";
import { GetCarrierNotificationConfigDetails, AddNotificationDetailsOnCarrierAndPlanLevel, getSelectedRole, ApprovedStatus, SubmittedStatus, Manager, SystemAdmin, Admin, NewStatus } from '../../Constants/AppConstants'
import axios from 'axios';
import moment from 'moment';
import { useMsal } from "@azure/msal-react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import BackArrow from "../../images/back-arrow.svg";
import { CatchAllNotificationDetails } from '../../Interfaces/NotificationDetails';
import { NotFound } from '../Carriers/NotFound';

export const PlanNotifications = () => {
    //Interface representing the details of a Checkboxes for plan level.
    interface PlanLevelCheckBoxes {
        insuranceCarrierId: number;
        insuranceHealthPlanId: number;
        sendSMS: boolean;
        sendEmail: boolean;
        pushNotification: boolean;
    }

    // Get MSAL instance and accounts
    const { accounts } = useMsal();

    // State variable for PlanNotificationDetails.
    const [PlanNotificationDetails, setPlanNotificationDetails] = useState<CatchAllNotificationDetails[]>();

    // State variable for carrierSearchText.
    const [search, setSearch] = useState('');

    // State variable for selectedRevokedData.
    const [selectedRevokedData, setSelectedRevokedData] = useState<CatchAllNotificationDetails | null>(null);

    // State variable for loader.
    const [loader, setLoader] = useState(true);

    // State variable for Revoke approval popup.
    const [show, setShow] = useState(false);

    //State variable for to store the revoke reason.
    const [revokeReason, setRevokeReason] = useState('');

    //State variable for sorting the data.
    const [sortedData, setSortedData] = useState(false);

    //State variable for recently updated button.
    const [isActiveRUpdated, setIsActiveRUpdated] = useState(false);

    //State variable for All button.
    const [isActiveAll, setIsActiveAll] = useState(true);

    //Store localStorage value into variable.
    const insuranceCarrierIdFromNotifications = localStorage.getItem('PlanNotificationsCarrierId');

    //Store localStorage value into variable.
    const selectedDateFromNotifications = localStorage.getItem('SelectedDate');

    //Store localStorage value into variable.
    const insuranceCarrierNameFromNotifications = localStorage.getItem('InsCarrierName');

    //It healps to navigate one page to another page.
    const navigate = useNavigate();

    //State variable for selected status for Filter checkboxes.
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

    //State variable for Status filters applied or not.
    const [appliedStatuses, setAppliedStatuses] = useState<string[]>([]);

    //State variable for selected User for Filter checkboxes.
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    //State variable for Users filters applied or not.
    const [appliedUsers, setAppliedUsers] = useState<string[]>([]);

    //State variable for selected Year for Filter checkboxes.
    const [selectedYears, setSelectedYears] = useState<string[]>([]);

    //State variable for Years filters applied or not.
    const [appliedYears, setAppliedYears] = useState<string[]>([]);

    //State variable for toggleFitlerVisibility.
    const [toggleFitlerVisibility, setToggleFitlerVisibility] = useState(false);

    //Handle filters toggle.
    const handletoggleFitlerVisibility = () => {
        setToggleFitlerVisibility(!toggleFitlerVisibility);
    };

    //Handle to stores which status is selected for filters.
    const handleStatusChange = (status: string) => {
        setSelectedStatuses(prevStatuses =>
            prevStatuses.includes(status)
                ? prevStatuses.filter(s => s !== status)
                : [...prevStatuses, status]
        );
    };

    //Handle to stores which User is selected for filters.
    const handleUserSelection = (user: string) => {
        setSelectedUsers((prevUser) =>
            prevUser.includes(user)
                ? prevUser.filter(u => u !== user)
                : [...prevUser, user]
        );
    };

    //Handle to stores which Year is selected for filters.
    const handleYearSelection = (year: string) => {
        setSelectedYears((prevYear) =>
            prevYear.includes(year) ? prevYear.filter((y) => y !== year) : [...prevYear, year]
        );
    };

    //Handle to filter for selected status.
    const applyStatusFilter = () => {
        setAppliedStatuses([...selectedStatuses]);
        setAppliedUsers([...selectedUsers]);
        setAppliedYears([...selectedYears]);
        setToggleFitlerVisibility(false);
    };

    //Handle clear selected filters.
    const clearFilters = () => {
        setSelectedStatuses([]);
        setSelectedUsers([]);
        setSelectedYears([]);
        setAppliedStatuses([]);
        setAppliedUsers([]);
        setAppliedYears([]);
        setToggleFitlerVisibility(false);
    };

    //Handle the plan level checkboxes data.
    const handlePlanLevelCheckboxChange = (carrierId: number, healthPlanId: number, carrierNotificationId: number, field: keyof PlanLevelCheckBoxes, value: boolean) => {
        if (!!carrierNotificationId) {
            setPlanNotificationDetails((prevDetails = []) =>
                prevDetails.map((carrier) =>
                    (carrier.carrierNotificationId === carrierNotificationId)
                        ? { ...carrier, [field]: value, notificationStatus: NewStatus }
                        : carrier
                )
            );
        }
        else {
            setPlanNotificationDetails((prevDetails = []) =>
                prevDetails.map((carrier) =>
                    (carrier.insuranceCarrierId === carrierId && carrier.insuranceHealthPlanId === healthPlanId)
                        ? { ...carrier, [field]: value, notificationStatus: NewStatus }
                        : carrier
                )
            );
        }
    };

    //Saving the carrier and plan level data && Revoke the carrier and plan level data.
    const saveCarrierAndPlanLevelDetails = async (
        insuranceCarrierId: number,
        insuranceHealthPlanId: number,
        actionType: string,
        releaseDate: string,
        sendSMS: boolean,
        sendEmail: boolean,
        pushNotification: boolean,
        notificationStatus: string,
        revokeComments: string,
        carrierNotificationId: number
    ) => {
        setLoader(true);

        try {
            // Construct API URL (use POST or PUT instead of GET)
            const apiUrl = `${AddNotificationDetailsOnCarrierAndPlanLevel}`;

            // Prepare payload with all required parameters
            const payload = {
                insuranceCarrierId,
                insuranceHealthPlanId,
                actionType,
                releaseDate,
                sendSMS,
                sendEmail,
                pushNotification,
                notificationStatus,
                revokeComments,
                modifyUser: accounts[0]?.name,
                carrierNotificationId
            };
            // Make the API call
            const response = await axios.post(apiUrl, payload);
            // Handle the API response
            if (response.status === 200) {
                fetchData();
            } else {
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            console.error("Error saving data:", error);
        } finally {
            setLoader(false);
        }
    };

    //Handle revoked data for carrier and plan level.
    const handleRevokeApproval = () => {
        if (!selectedRevokedData) return;
        const revokeComment = revokeReason.trim() ? revokeReason : "Revoke configuration by user";
        saveCarrierAndPlanLevelDetails(
            selectedRevokedData.insuranceCarrierId,
            selectedRevokedData.insuranceHealthPlanId,
            'BenefitLoad',
            selectedRevokedData.releaseDate,
            selectedRevokedData.sendSMS,
            selectedRevokedData.sendEmail,
            selectedRevokedData.pushNotification,
            'Revoked',
            revokeComment,
            selectedRevokedData.carrierNotificationId ?? null
        );
        handleClose();
        setRevokeReason("");
    };

    // It retrives the plan details based on carrierid.
    const fetchData = async () => {
        setLoader(true);
        try {
            const apiUrl = `${GetCarrierNotificationConfigDetails}/${selectedDateFromNotifications}?InsuranceCarrierID=${insuranceCarrierIdFromNotifications}`;
            const response = await axios.get(apiUrl);
            setPlanNotificationDetails(response?.data?.filter((x: any) => x));
            setLoader(false);
        }
        catch (error) {
            console.log(error);
            setLoader(false);
        }
    };

    //It helps intially load the carriers.
    useEffect(() => {
        fetchData();
    }, []);

    //Handle recently updated click.
    const handleRecentlyUpdatedClick = () => {
        setIsActiveRUpdated(true);
        setIsActiveAll(false);
        setSortedData(true);
        GetPlans();
    };

    //Handle ALL click.
    const handleAllClick = () => {
        setIsActiveAll(true);
        setIsActiveRUpdated(false);
        setSortedData(false);
        GetPlans();
    }

    //It healps to close the revoke popup.
    const handleClose = () => {
        setShow(false);
        setSelectedRevokedData(null)
    }

    //It healps to open the revoke popup.
    const handleRevoke = (carrierNotificationId: number) => {
        const filteredData = PlanNotificationDetails?.find(
            (item) => item?.carrierNotificationId === carrierNotificationId);

        if (filteredData) {
            setSelectedRevokedData(filteredData);
        } else {
            setSelectedRevokedData(null);
        }
        setShow(true);
    }

    //Handle which carriers are mapped to dashboard.
    const GetPlans = () => {
        let filteredData: CatchAllNotificationDetails[] = PlanNotificationDetails || [];

        // Apply search carriers
        if (search?.length >= 3) {
            filteredData = filteredData?.filter(x => x?.healthPlanName?.toLowerCase().includes(search.toLowerCase()))
        }

        // Apply filter for filters like Status,UserName,Year.
        if (appliedStatuses.length > 0 || appliedUsers.length > 0 || appliedYears.length > 0) {
            filteredData = filteredData.filter((x: CatchAllNotificationDetails) =>
                (appliedStatuses.length === 0 || appliedStatuses.includes(x.notificationStatus)) &&
                (appliedUsers.length === 0 || appliedUsers.includes(x.modifyUser)) &&
                (appliedYears.length === 0 || appliedYears.includes(new Date(x.releaseDate).getFullYear().toString()))
            );
        }

        // Apply sorted for recently updated
        if (sortedData) {
            filteredData = [...filteredData].sort(
                (a: CatchAllNotificationDetails, b: CatchAllNotificationDetails) =>
                    new Date(b.modifyDate).getTime() - new Date(a.modifyDate).getTime()
            );
        }
        return filteredData
    }

    //Handle click event
    const handleClickevents = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation(); // Prevent the click event from propagating to the Dropdown.Item
    };

    //It will store the all users In array.
    const allUsers = Array.from(new Set(
        (PlanNotificationDetails ?? [])
            .map((carrier: CatchAllNotificationDetails) => carrier?.modifyUser)
            .filter((user: string) => user && user.trim() !== "")
    ));

    //Handle date changes.
    const handleDateChange = (event: ChangeEvent<HTMLInputElement>, plan: CatchAllNotificationDetails) => {
        const selectedDate = event.target.value;
        if (!!plan?.carrierNotificationId) {
            const updatedPlanLevelNotificationDetails = PlanNotificationDetails?.map(currentPlan =>
                currentPlan?.carrierNotificationId === plan?.carrierNotificationId
                    ? { ...currentPlan, releaseDate: selectedDate, notificationStatus: NewStatus }
                    : currentPlan
            );
            setPlanNotificationDetails(updatedPlanLevelNotificationDetails);
        }
        else {
            const updatedPlanLevelNotificationDetails = PlanNotificationDetails?.map(currentPlan =>
                currentPlan?.insuranceCarrierId === plan?.insuranceCarrierId &&
                    currentPlan?.insuranceHealthPlanId === plan?.insuranceHealthPlanId
                    ? { ...currentPlan, releaseDate: selectedDate, notificationStatus: NewStatus }
                    : currentPlan
            );
            setPlanNotificationDetails(updatedPlanLevelNotificationDetails);
        }
    }

    //Handle time changes.
    const handleTimeChange = (event: ChangeEvent<HTMLInputElement>, plan: CatchAllNotificationDetails) => {
        let time = event.target.value;
        if (!!plan.carrierNotificationId) {
            const updatedPlanLevelNotificationDetails = PlanNotificationDetails?.map(currentPlan => {
                if (currentPlan?.carrierNotificationId === plan?.carrierNotificationId) {
                    const currentDate = moment(currentPlan?.releaseDate).local();
                    const [hours, minutes] = time.split(':').map(Number);
                    currentDate.set({ hour: hours, minute: minutes });
                    return {
                        ...currentPlan,
                        releaseDate: currentDate.format("YYYY-MM-DDTHH:mm"), notificationStatus: NewStatus
                    };
                }
                return currentPlan;
            });
            setPlanNotificationDetails(updatedPlanLevelNotificationDetails);
        }
        else {
            const updatedPlanLevelNotificationDetails = PlanNotificationDetails?.map(currentPlan => {
                if (currentPlan?.insuranceCarrierId === plan?.insuranceCarrierId &&
                    currentPlan?.insuranceHealthPlanId === plan?.insuranceHealthPlanId) {
                    const currentDate = moment(currentPlan?.releaseDate).local();
                    const [hours, minutes] = time.split(':').map(Number);
                    currentDate.set({ hour: hours, minute: minutes });
                    return {
                        ...currentPlan,
                        releaseDate: currentDate.format("YYYY-MM-DDTHH:mm"), notificationStatus: NewStatus
                    };
                }
                return currentPlan;
            });
            setPlanNotificationDetails(updatedPlanLevelNotificationDetails);
        }
    }

    const getNotificationStatusColor = (notificationStatus: string) => {
        if (notificationStatus.toLowerCase() === "new") {
            return "new";
        }
        else if (notificationStatus.toLowerCase() === 'approved') {
            return "approved";
        }
        else if (notificationStatus.toLowerCase() === 'revoked') {
            return "failed"
        }
        else if (notificationStatus.toLowerCase() === 'submitted') {
            return "draft"
        }
    }

    const handleCancelClick = (insuranceCarrierId: number, insuranceHealthPlanId: number) => {
        const updatedPlanNotificationDetails = PlanNotificationDetails?.map(currentPlan =>
            (currentPlan.insuranceCarrierId === insuranceCarrierId && currentPlan.insuranceHealthPlanId === insuranceHealthPlanId)
                ? {
                    ...currentPlan, sendSMS: false, sendEmail: false, pushNotification: false, releaseDate: ''
                }
                : currentPlan
        );
        setPlanNotificationDetails(updatedPlanNotificationDetails);
    }

    return (
        <div className={`d-flex overflow-hidden ${(loader === true ? "loading-skeleton" : "")}`}>
            <SideBar />

            <div className="d-flex overflow-hidden notification-page">
                <div className="page-content">
                    <div className='page-title-section'>
                        <div className="page-top-left">
                            <Breadcrumb className="breadcrumbs d-none d-lg-block">
                                <Breadcrumb.Item className="cursor-pointer" onClick={() => navigate("/Notifications")}>Benefit Load Notification</Breadcrumb.Item>
                                <Breadcrumb.Item active>{insuranceCarrierNameFromNotifications}</Breadcrumb.Item>
                            </Breadcrumb>
                            <h3><a href="/Notifications"><img className="img-fluid" src={BackArrow} alt=" " /></a> <span title="title here">{insuranceCarrierNameFromNotifications} - Benefit Load Notification</span></h3>
                        </div>
                    </div>

                    <div className="page-content-bottom notifications-container">
                        <h5>Available Notifications</h5>
                        <p className="sub-text">Please choose the configuration you want to make</p>
                        <div className="table-actions">
                            <div className="activity-buttons">
                                <Button className={`btn-outline ${isActiveAll ? "active" : ""}`} onClick={handleAllClick}>All</Button>
                                <Button className={`btn-outline ${isActiveRUpdated ? "active" : ""}`} onClick={handleRecentlyUpdatedClick} >Recently Updated</Button>
                                <div className="filter-section">
                                    <Dropdown show={toggleFitlerVisibility}>
                                        <Dropdown.Toggle variant="" id="dropdown-basic" className="filters" onClick={handletoggleFitlerVisibility}>
                                            <img className="img-fluid me-2" src={Filter} alt=" " />
                                            <span>Filters</span>
                                            {(appliedStatuses?.length > 0 ||
                                                appliedUsers?.length > 0 ||
                                                appliedYears?.length > 0) && (
                                                    <div className="filter-notification"></div>
                                                )}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="filter-dropdown">
                                            <h5 className="mb-3">Filter by</h5>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="Status" className="form-field w-100">
                                                    <input className="form-control search-input" type="search" placeholder="Status" aria-label="Search" data-bs-toggle="dropdown"
                                                        data-bs-display="static" aria-expanded="false"
                                                        value={selectedStatuses.length > 0 ? selectedStatuses.join(", ") : ""} readOnly />
                                                    <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {["New", "Submitted", "Approved", "Revoked"].map((status) => (
                                                        <Dropdown.ItemText key={status} >
                                                            <div className="input-box">
                                                                <Form.Check
                                                                    className="form-check" id={status}
                                                                    checked={selectedStatuses.includes(status)}
                                                                    onChange={() => handleStatusChange(status)}
                                                                />
                                                                <label htmlFor={status}>{status}</label>
                                                            </div>
                                                        </Dropdown.ItemText>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="CreateUser" className="form-field w-100">
                                                    <input
                                                        className="form-control search-input"
                                                        type="search"
                                                        placeholder="CreateUser"
                                                        aria-label="Search"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-display="static"
                                                        aria-expanded="false"
                                                        value={selectedUsers.length > 0 ? selectedUsers.join(", ") : ""} readOnly />
                                                    <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {allUsers.map((user) => (
                                                        <Dropdown.ItemText key={user} onClick={handleClickevents} >
                                                            <div className="input-box">
                                                                <Form.Check
                                                                    className="form-check" id={user}
                                                                    checked={selectedUsers.includes(user)}
                                                                    onChange={() => handleUserSelection(user)}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                />
                                                                <label htmlFor={user}>{user}</label>
                                                            </div>
                                                        </Dropdown.ItemText>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="Year" className="form-field w-100">
                                                    <input
                                                        className="form-control search-input"
                                                        type="search"
                                                        placeholder="Year"
                                                        aria-label="Search"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-display="static"
                                                        aria-expanded="false"
                                                        value={selectedYears.length > 0 ? selectedYears.join(", ") : ""} readOnly />
                                                    <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {["2025", "2026"].map((year) => (
                                                        <Dropdown.ItemText key={year} onClick={handleClickevents}>
                                                            <div className="input-box">
                                                                <Form.Check
                                                                    className="form-check"
                                                                    id={year}
                                                                    checked={selectedYears.includes(year)}
                                                                    onChange={() => handleYearSelection(year)}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                />
                                                                <label htmlFor={year}>{year}</label>
                                                            </div>
                                                        </Dropdown.ItemText>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <div className="filter-buttons">
                                                <Button className="btn-secondary btn-sm" onClick={applyStatusFilter}>Apply</Button>
                                                <Button className="btn-sm btn-outline-primary" onClick={clearFilters}>Clear</Button>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                {/* <span className="filter-result">Showing 0 carriers</span> */}
                            </div>

                            <div className="form-field table-search features-search">
                                <FloatingLabel controlId="floatingInput" label="Search Plan" className="m-0">
                                    <input className="form-control search-input"
                                        type="search"
                                        placeholder="Search Plan"
                                        aria-label="Search"
                                        data-bs-toggle="dropdown"
                                        data-bs-display="static"
                                        aria-expanded="false"
                                        onChange={(e) => setSearch(e.target.value)} value={search}
                                    />
                                    {search?.length === 0 ? <Image src={Search} className="form-field-icon" alt="" /> : <></>}
                                </FloatingLabel>
                            </div>
                        </div>
                        <div className="table-responsive table-radius expandable-table">
                            <Scrollbars
                                autoHide
                                autoHideTimeout={400}
                                autoHideDuration={200}
                                thumbMinSize={80}
                                autoHeight
                                autoHeightMin={60}
                                autoHeightMax={490}
                            >
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th className="w-475">Plans</th>
                                            <th className="w-84 text-center">SMS</th>
                                            <th className="w-84 text-center">Email</th>
                                            <th className="w-84 text-center">Push</th>
                                            <th className="w-248">Release Date <span style={{ color: "red" }}>*</span></th>
                                            <th className="w-248">Release Time (EST) <span style={{ color: "red" }}>*</span></th>
                                            <th className="w-220">Status</th>
                                            <th className="w-242">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {GetPlans().length > 0 ? GetPlans()
                                            ?.map((plan: CatchAllNotificationDetails, index: number) => (
                                                <tr key={index}>
                                                    <td className="w-475">{plan?.healthPlanName}</td>
                                                    <td className="w-84 text-center">
                                                        <div className="input-box">
                                                            <Form.Check
                                                                aria-label="Send SMS"
                                                                className="form-check"
                                                                checked={plan?.sendSMS ?? false}
                                                                disabled={plan?.notificationStatus === ApprovedStatus && !!plan?.carrierNotificationId ? true : false}
                                                                onClick={(e) => e.stopPropagation()}
                                                                onChange={(e) =>
                                                                    handlePlanLevelCheckboxChange(
                                                                        plan?.insuranceCarrierId,
                                                                        plan?.insuranceHealthPlanId,
                                                                        plan?.carrierNotificationId,
                                                                        "sendSMS",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="w-84 text-center">
                                                        <div className="input-box">
                                                            <Form.Check
                                                                aria-label="Send Email"
                                                                className="form-check"
                                                                checked={plan?.sendEmail ?? false}
                                                                disabled={plan?.notificationStatus === ApprovedStatus && !!plan?.carrierNotificationId ? true : false}
                                                                onClick={(e) => e.stopPropagation()}
                                                                onChange={(e) =>
                                                                    handlePlanLevelCheckboxChange(
                                                                        plan?.insuranceCarrierId,
                                                                        plan?.insuranceHealthPlanId,
                                                                        plan?.carrierNotificationId,
                                                                        "sendEmail",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="w-84 text-center">
                                                        <div className="input-box">
                                                            <Form.Check
                                                                aria-label="Push Notification"
                                                                className="form-check"
                                                                checked={plan?.pushNotification ?? false}
                                                                disabled={plan?.notificationStatus === ApprovedStatus && !!plan?.carrierNotificationId ? true : false}
                                                                onClick={(e) => e.stopPropagation()}
                                                                onChange={(e) =>
                                                                    handlePlanLevelCheckboxChange(
                                                                        plan?.insuranceCarrierId,
                                                                        plan?.insuranceHealthPlanId,
                                                                        plan?.carrierNotificationId,
                                                                        "pushNotification",
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className='date-field'>
                                                        <input
                                                            className="form-control w-100"
                                                            type="date"
                                                            readOnly={plan?.notificationStatus === ApprovedStatus && !!plan?.carrierNotificationId ? true : false}
                                                            value={
                                                                plan?.releaseDate
                                                                    ? moment(plan?.releaseDate).format("YYYY-MM-DD")
                                                                    : "Select Date"
                                                            }
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            min={moment().format("YYYY-MM-DD")}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={(e) => handleDateChange(e, plan)}
                                                            placeholder="Select Date"
                                                        />
                                                    </td>
                                                    <td className='date-field'>
                                                        <input
                                                            type="time"
                                                            placeholder="Enter Time"
                                                            readOnly={plan?.notificationStatus === ApprovedStatus && !!plan?.carrierNotificationId ? true : false}
                                                            className="form-control"
                                                            value={
                                                                plan?.releaseDate
                                                                    ? moment(plan?.releaseDate).format("HH:mm")
                                                                    : "Select Time"
                                                            }
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={(e) => handleTimeChange(e, plan)}
                                                        />
                                                    </td>
                                                    <td className="w-220"><span className={`status ${(getNotificationStatusColor(plan?.notificationStatus ?? NewStatus))}`}>{plan?.notificationStatus ?? NewStatus}</span></td>
                                                    <td className="w-242">
                                                        <div className="action-btns">
                                                            {((plan?.notificationStatus === ApprovedStatus) && !!plan?.carrierNotificationId) ? (
                                                                <Link to="#"
                                                                    onClick={() => handleRevoke(plan?.carrierNotificationId)}
                                                                    className="custom-link"
                                                                    title="Revoke Approval"
                                                                >
                                                                    <img className="img-fluid revoke-icon" src={Revoke} alt="Revoke Approval" /> Revoke Approval
                                                                </Link>
                                                            ) : (
                                                                    <>
                                                                        <Button className="btn-outline-primary" disabled={(plan?.notificationStatus ?? NewStatus) === NewStatus ? false : true} onClick={() => handleCancelClick(plan?.insuranceCarrierId, plan?.insuranceHealthPlanId)}>
                                                                        <img className="img-fluid" src={CrossIcon} alt="Remove" />
                                                                    </Button>
                                                                        <Button className="btn-secondary" disabled={!plan?.releaseDate}
                                                                        onClick={() =>
                                                                            saveCarrierAndPlanLevelDetails(
                                                                                plan?.insuranceCarrierId,
                                                                                plan?.insuranceHealthPlanId,
                                                                                'BenefitLoad',
                                                                                plan?.releaseDate.toString(),
                                                                                plan?.sendSMS ?? false,
                                                                                plan?.sendEmail ?? false,
                                                                                plan?.pushNotification ?? false,
                                                                                getSelectedRole()?.toLowerCase() === Manager.toLowerCase() ? SubmittedStatus : (getSelectedRole()?.toLowerCase() === Admin.toLowerCase() || getSelectedRole()?.toLowerCase() === SystemAdmin.toLowerCase()) ? ApprovedStatus : '',
                                                                                '',
                                                                                plan?.carrierNotificationId
                                                                            )
                                                                        }
                                                                    >
                                                                        <img className="img-fluid" src={CheckIcon} alt="Approve" />
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            ) :
                                            <NotFound message={"No Plans found"} colSpan={8} />
                                        }
                                    </tbody>
                                </Table>
                            </Scrollbars>

                            <div />

                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Revoke Approval</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="mh-200">
                        <h5>Do you want to revoke approval for the selected notifications?</h5>
                        <p>The notifications will not be sent to members until the plan is approved.</p>
                        <textarea className="form-control" placeholder="Enter your reason for revoking approval" value={revokeReason}
                            onChange={(e) => setRevokeReason(e.target.value)}></textarea>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-start">
                        <Button className="btn-secondary" onClick={handleRevokeApproval}> Revoke Approval
                        </Button>
                        <Button className="btn-outline-primary" onClick={handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}